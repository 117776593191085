/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import "@fontsource/montserrat"
import "@fontsource/montserrat/900.css"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled";

import Header from "./header"
import "./layout.css"
import Footer from "./footer";

const SiteContainer = styled.div`
  background: var(--background);
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <SiteContainer>
        <main>{children}</main>
      <Footer />
      </SiteContainer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
