import * as React from "react"
import { css } from "@emotion/react"

const ContentWrapper = ({ children }) => {
  return (
    <div
      css={css`
        max-width: var(--siteWidth);
        margin: 0 auto;
      `}
    >
      {children}
    </div>
  )
}

export default ContentWrapper
