import * as React from "react"
import styled from "@emotion/styled"
import {Link} from "gatsby"

const Container = styled.div`
  background: linear-gradient(to right, var(--green), var(--darkGreen));
  
  .inner {
    margin: 0 auto;
    max-width: var(--siteWidth);
    padding: 3em 1em;
    display: grid;
    grid-template-columns: 1fr;
    place-self: center;
    
    h2 {
      color: var(--black);
      margin-top: 0;
    }
    a {
      margin-top: 2rem;
      text-align: center;
      font-weight: 900;
      
      button {
        background: var(--black);
        color: white;
        border: none;
        padding: 1em;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }
`

const FreeConsultation = () => (
  <>
    <Container>
      <div className="inner">
          <h2>Call to book a Free Consultation</h2>
        <Link to="/contact/" title="contact labelle bookkeeping">
          <button>
            Contact Me
          </button>
          </Link>
      </div>
    </Container>
  </>
)

export default FreeConsultation
