import * as React from "react"
import styled from "@emotion/styled"
import {useState} from "react"
import {Link} from "gatsby"

import CloseMenu from "../images/menuStuff/x_white.svg"

const NavMobile = styled.div`
  overflow: auto;
  /* vertical scrollbar */

  overflow-x: hidden;
  /* horizontal scrollbar */
  position: fixed;
  background: linear-gradient(312deg, var(--green), var(--darkGreen));
  
  height: 100%;
  width: 280px;
  /* there's a natural 4px that needs to be added for fixed position to avoid the shifting */
  top: 0;
  right: 0;
  text-transform: capitalize;
  z-index: 9999;
`

const LogoWrapper = styled.div`
  margin: 16px 8px 16px 8px;
  overflow: hidden;

  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;

  .logo {
    margin: 0 auto;
  }
`

const CloseButton = styled.div`
  margin: 0 auto;
`

const CloseButtonButton = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
`

const LinksList = styled.ul`
  list-style-type: none;
  margin: 0 auto;
  padding-left: 0;

  li {
    margin: 16px 0;
    text-align: center;

    a {
      color: white;
      font-weight: bold;
      font-family: var(--headingFont);
      text-decoration: none;
      font-size: 1em;

      @media (min-width: 768px) {
        font-size: 18px;
      }
    }

    a:hover {
      text-decoration: underline;
      font-weight: bold;
    }

    a:visited {
      color: white;
    }
  }

  //submenu styling
  ul li {
    list-style-type: none;
    margin: 16px 0;
    padding-left: 0;
  }
`

const BottomTitle = styled.div`
  margin-top: 64px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  //background: black;
`

const ButtonStyle = styled.div`
  font-size: 16px;
  background: black;
  border: none;
  padding: 16px;
  color: white;
  text-decoration: none;
  font-weight: bold;
  text-transform: capitalize;
`

const ReturnNav = styled.nav`
  position: relative;
`

const ReturnButton = styled.button`
  position: relative;

  margin-top: 12px;
  background: transparent;
  border: none;

  z-index: 1000;

  span {
    background: linear-gradient(312deg, var(--green), var(--darkGreen));
    background-size: 400% 400%;
    animation: spanBG 6s ease infinite;

    @keyframes spanBG {
      0% {
        background-position: 0, 2%;
      }
      50% {
        background-position: 100% 99%;
      }
      100% {
        background-position: 0 2%;
      }
    }

    display: block;
    width: 50px;
    height: 4px;
    margin-top: 8px;
    margin-bottom: 8px;
    position: relative;

    border-radius: 3px;

    z-index: 1;
  }

  &:hover {
    cursor: pointer;
  }
`

const MainMenu = [
  {
    title: "Home",
    alt: "Bookkeeping Services",
    url: "/",

  },
  {
    title: "Bookkeeping Services",
    alt: "General Bookkeeping Services",
    url: "/services/",
    subMenu: [
      {
        title: "Quickbooks ProAdvising",
        alt: "Quickbooks Online ProAdvising",
        url: "/services/quickbooks-online-proadvisor/",
      },
      {
        title: "Accounts Payable",
        alt: "Accounts Payable",
        url: "/services/accounts-payable/",
      },
      {
        title: "Accounts Receivable",
        alt: "Accounts Receivable",
        url: "/services/accounts-receivable/",
      },
      {
        title: "Payroll Services",
        alt: "Payroll Services",
        url: "/services/payroll-services/",
      },
    ],
  },
  {
    title: "Contact",
    alt: "Contact Labelle Bookkeeping",
    url: "/contact/",
    id: "003",
    order: 3,
  },
]

const Navigation = () => {
  const [showMenu, setShowMenu] = useState(false)

  const ToggleMenu = () => {
    setShowMenu(!setShowMenu)
  }

  let menu

  if (showMenu) {
    menu = (
      <>
        <NavMobile>
          {/* start of logo and menu */}
          <LogoWrapper>
            <CloseButton>
              <CloseButtonButton onClick={ToggleMenu}>
                <img
                  height="50px"
                  width="50px"
                  src={CloseMenu}
                  alt="close menu"
                  loading="lazy"
                />
              </CloseButtonButton>
            </CloseButton>
          </LogoWrapper>
          {/* end logo and menu */}

          {/* start of links */}
          <div>
            {MainMenu.map((menuItem, i) => {
              if (menuItem.subMenu) {
                return (
                  <LinksList>
                    <li>
                      <Link to={menuItem.url}
                            title={menuItem.alt}
                            onClick={() => setShowMenu(!showMenu)}>
                        {menuItem.title}
                      </Link>
                    </li>
                    <ul key={i}>
                      {menuItem.subMenu.map((subMenu, i) => {
                        return (
                          <>
                            <li>
                              <Link to={subMenu.url}
                                    title={subMenu.alt}
                                    onClick={() => setShowMenu(!showMenu)}>
                                {subMenu.title}
                              </Link>
                            </li>
                          </>
                        )
                      })}
                    </ul>
                  </LinksList>
                )
              } else {
                return (
                  <LinksList key={i}>
                    <li>
                      <Link to={menuItem.url}
                            title={menuItem.alt}
                            onClick={() => setShowMenu(!showMenu)}>
                        {menuItem.title}
                      </Link>
                    </li>
                  </LinksList>
                )
              }
            })}
          </div>
          {/* end of links */}
          <BottomTitle>
            <a href="tel:+16047990197" title="call 604.799.0197">
              <ButtonStyle>Call Now</ButtonStyle>
            </a>
          </BottomTitle>
        </NavMobile>
      </>
    )
  }

  return (
    <>
      <ReturnNav>
        <ReturnButton
          aria-label="menu button"
          onClick={() => setShowMenu(!showMenu)}
        >
          <span />
          <span />
          <span />
        </ReturnButton>
        {menu}
      </ReturnNav>
    </>
  )
}
export default Navigation
