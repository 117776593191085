import * as React from "react"
import styled from "@emotion/styled"
import { Link, navigate } from "gatsby"
import {StaticImage} from "gatsby-plugin-image";
import quickbooksLogo from "../images/quickbooks_logo.png"
import launchCert from "../images/bookkeeper-launch-certificate-of-completion.png"
import FreeConsultation from "./freeConsultation";

const Clout = styled.div`
  background: var(--grey);
  text-align: center;
  color: white;
  font-size: 16px;
  margin: 0;
  padding: 0;
  
  p, a {
    margin: 0;
    padding: 0;
    color: white;
  }
  p {
    padding: 4px 0;
  }
`

const FooterContainer = styled.div`
  background: var(--grey);
  margin: 0;
  padding: 0;
  
  .inner {
    max-width: var(--siteWidth);
    margin: 0 auto;
    padding: 3em 0;
  }
  
  .grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "logoBox" "infoBox";
    
    gap: 2em;
    
    .logoBox {grid-area: logoBox;}
    .menuBox {grid-area: infoBox;}
    
    .logoBox {
      margin: 0 auto;
      cursor: pointer;
    }
    
    .infoBox {
      display: grid;
      flex-direction: row;
      
      justify-content: center;
      align-items: center;
      
      place-self: center;
      gap: 1em;
      
      margin-top: 2em;
      
      text-align: center;
      
      img {
        margin: 0 auto;
        padding: 0;
      }
      
      p {
        color: white;
      }
      
      a {
        color: white;
      }
      
      @media (min-width: 768px) {
        justify-content: flex-end;
        margin-top: 0;
      }
    }
    
    @media(min-width: 768px) {
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "logoBox infoBox";
      justify-content: space-between;
    }
  }
`

const Footer = () => (
  <footer>
    <FreeConsultation />
    <FooterContainer>
    <div className="inner grid">
      <div className="logoBox">
        <StaticImage src="../images/labelle_logo_footer.png"
                     loading="lazy"
                     height={200}
                     formats={["auto", "webp"]}
                     alt="Labelle Bookkeeping logo footer version"
                     onClick={() => navigate("/")}/>
      </div>
      <div className="infoBox">

          <p>
            Tel: <a href="tel:1-236-471-6617" title="call labelle booking at 1-236-471-6617">236.471.6617</a><br/>All Rights Reserved.
          </p>
          <p>
            <Link to="/sitemap/" title="sitemap for labelle bookkeeping">
              Sitemap
            </Link>
          </p>
          <div style={{
              display: `grid`,
              gridTemplateColumns: `1fr 1fr`,
              margin: `0 auto`,
          }}>
          <img src={quickbooksLogo}
               loading="lazy"
               height="158px"
               width="118px"
               alt="Labelle Bookkeeping quickbooks certified"/>
          <img src={launchCert}
               loading="lazy"
               height="158px"
               width="auto"
               alt="Labelle Bookkeeping quickbooks certified"/>
        </div>
      </div>
    </div>
  </FooterContainer>
    <Clout>
      <p>Raw Media, <a href="https://rawmedia.ca/services/website-design/" title="website design services" target="_blank" rel="noopener noreferrer">Web Design</a></p>
    </Clout>
  </footer>
)

export default Footer