/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SeoQuery = graphql`
  {
    site {
      siteMetadata {
        siteTitle: title
        siteDescription: description
        siteAuthor: author
        siteLogo
        siteUrl
      }
    }
  }
`

function Seo({
  author,
  description,
  lang,
  meta,
  title,
  children,
  canonical,
  image,
}) {
  const {
    site: {
      siteMetadata: {
        siteAuthor,
        siteDescription,
        siteLogo,
        siteTitle,
        siteUrl,
      },
    },
  } = useStaticQuery(SeoQuery)

  const metaAuthor = author || siteAuthor
  const metaDescription = description || siteDescription
  const defaultTitle = siteTitle // consider replacing this with the metaTitle constant as the default would be the siteTitle unless specified by the title prop
  const metaTitle = title || siteTitle
  const metaImage = image || `${siteUrl}${siteLogo}`
  const defaultCanonical = canonical || null

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: "og:image",
          content: metaImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          property: "twitter:image",
          content: metaImage,
        },
        {
          name: `twitter:creator`,
          content: metaAuthor,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link rel="canonical" href={defaultCanonical} />
      {children}
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  image: ``,
}

Seo.propTypes = {
  author: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  image: PropTypes.any,
  title: PropTypes.string.isRequired,
  canonical: PropTypes.string,
  children: PropTypes.any,
}

export default Seo
