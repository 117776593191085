import * as React from "react"
import { keyframes} from "@emotion/react";
import styled from "@emotion/styled"
import { navigate } from "gatsby"
import {StaticImage} from "gatsby-plugin-image";
// import Headroom from "react-headroom"
import Navigation from "./nav";

const straightGreenline = keyframes`
  0%{background-position: 2%}
  50%{background-position:100% 99%}
  100%{background-position: 2%}
`

const HeaderContainer = styled.header`
  background: var(--grey);
  margin: 0;
  padding: 0;;
  
  .greenLine {
    height: 8px;
    background: linear-gradient(to right, var(--green), var(--darkGreen));
    //background: linear-gradient(to right, var(--green), var(--darkGreen));
    background-size: 400% 400%;
    animation: ${straightGreenline} 6s ease infinite;
  }
  
  .inner {
    max-width: var(--siteWidth);
    margin: 0 auto;
    padding: 1.5em 0;
  }
  
  .grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "logoBox" "menuBox";
    
    
    .spacer {grid-area: spacer;}
    .logoBox {grid-area: logoBox;}
    .menuBox {grid-area: menuBox;}
    
    .logoBox {
      margin: 0 auto;
      cursor: pointer;
    }
    
    .menuBox {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    @media(min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas: "spacer logoBox menuBox";
      justify-content: space-between;
    }
  }
`

const Header = () => (
  // <Headroom style={{ zIndex: `999` }}>
    <HeaderContainer>
    <div className="inner grid">
      <div className="spacer"/>
      <div className="logoBox">
        <StaticImage src="../images/labelle_logo_header.png"
                     height={100}
                     formats={["auto", "webp"]}
                     alt="Labelle Bookkeeping logo"
                     onClick={() => navigate("/")}/>
      </div>
      <div className="menuBox">
        <Navigation/>
      </div>
    </div>
    <div className="greenLine"/>
  </HeaderContainer>
  // </Headroom>
)

export default Header
